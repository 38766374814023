import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import shape from "../../assets/images/common/shape_3.png";
import shape1 from "../../assets/images/common/shape_4.svg";
import { useState } from "react";

//!

Team.propTypes = {
  data: PropTypes.array,
};

function Team(props) {
  const { data } = props;

  const [dataTitle] = useState({
    title: "Meet the team",
  });
  return (
    <section className="tf-section tf_team">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="tf-title mb40" data-aos="fade-up" data-aos-duration="800">
              <h2 className="title">{dataTitle.title}</h2>
            </div>
          </div>
          <div className="col-md-12">
            <div className="team-box-wrapper">
              {data.map((item) => (
                <div
                  key={item.id}
                  className="team-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="800"
                >
                  <img className="shape" src={shape} alt="" />
                  <img className="shape_ecfect" src={shape1} alt="" />
                  <div className="image">
                    <img src={item.img} alt="" />
                  </div>
                  <div className="content">
                    <h5 className="name">
                      <Link to="#">{item.name}</Link>
                    </h5>
                    <p className="position">{item.unit}</p>
                    {item.social && (
                      <ul className="social">
                        <li>
                          <Link to={item.social} target="_blank">
                            <svg
                              width="19"
                              height="18"
                              viewBox="0 0 19 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.3003 17.8V11.354C18.3003 8.18599 17.6183 5.76599 13.9223 5.76599C12.1403 5.76599 10.9523 6.73399 10.4683 7.65799H10.4243V6.05199H6.92627V17.8H10.5783V11.97C10.5783 10.43 10.8643 8.95599 12.7563 8.95599C14.6263 8.95599 14.6483 10.694 14.6483 12.058V17.778H18.3003V17.8Z"
                                fill="#798DA3"
                              />
                              <path
                                d="M0.986328 6.052H4.63833V17.8H0.986328V6.052Z"
                                fill="#798DA3"
                              />
                              <path
                                d="M2.8122 0.200012C1.6462 0.200012 0.700195 1.14601 0.700195 2.31201C0.700195 3.47801 1.6462 4.44601 2.8122 4.44601C3.9782 4.44601 4.9242 3.47801 4.9242 2.31201C4.9242 1.14601 3.9782 0.200012 2.8122 0.200012Z"
                                fill="#798DA3"
                              />
                            </svg>
                          </Link>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
