import React from "react";
import PropTypes from "prop-types";

PageTitle.propTypes = {
  title: PropTypes.string,
};

function PageTitle() {
  return (
    <section className="page-title">
      <div className="container">
        <div className="row">
          <div className="col-md-12"></div>
        </div>
      </div>
    </section>
  );
}

export default PageTitle;
