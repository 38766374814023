import React, { useState } from "react";
import { api } from "../services/api";
import PageTitle from "../components/pagetitle";
import { ScheduleMeeting } from "react-schedule-meeting";
import { useForm } from "react-hook-form";
import { Toaster, toast } from "react-hot-toast";

function SubmitIGO(props) {
  const [selectedDate, setSelectedDate] = useState();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
  });

  async function onSubmit(data) {
    if (
      !selectedDate ||
      selectedDate === "" ||
      selectedDate === null ||
      selectedDate === undefined
    ) {
      toast.error("You must select a date!", {
        style: {
          border: "1px solid #713200",
          padding: "16px",
          color: "#000000",
        },
        iconTheme: {
          primary: "#c252e1",
          secondary: "#FFFAEE",
        },
      });
      return;
    }
    const formattedDate = new Date(selectedDate).toISOString();
    data.start = formattedDate;
    await api.post("/reservation", data);
    toast.success("Your reservation is registered successfully!", {
      style: {
        border: "1px solid #713200",
        padding: "16px",
        color: "#000000",
      },
      iconTheme: {
        primary: "#c252e1",
        secondary: "#FFFAEE",
      },
    });
    setSelectedDate(null);
    reset();
  }
  // filter out today and the weekend days
  const availableDays = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  ].filter(
    (id) =>
      new Date(new Date().setDate(new Date().getDate() + id)).getDay() !== 0 &&
      new Date(new Date().setDate(new Date().getDate() + id)).getDay() !== 6
  );

  const availableTimeslots = availableDays.map((id) => {
    return {
      id,
      startTime: new Date(
        new Date(new Date().setDate(new Date().getDate() + id)).setHours(9, 0, 0, 0)
      ),
      endTime: new Date(
        new Date(new Date().setDate(new Date().getDate() + id)).setHours(18, 0, 0, 0)
      ),
    };
  });

  return (
    <div className="inner-page home2">
      {<PageTitle title="Make an appointment " />}

      <section className="tf-section project-info pt60 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <div className="project-info-form">
                  <h6 className="title">Make an appointment </h6>
                  <div className="form-inner">
                    <fieldset>
                      <label>Name</label>
                      <input
                        type="text"
                        placeholder="Enter your name"
                        required
                        name="name"
                        {...register("name", {
                          required: true,
                        })}
                      />
                    </fieldset>
                    {errors.name && <p style={{ color: "#C252E0" }}>Please provide your name</p>}

                    <fieldset>
                      <label>Email address</label>
                      <input
                        type="email"
                        placeholder="Enter your email"
                        required
                        name="email"
                        {...register("email", {
                          required: true,
                          pattern:
                            // eslint-disable-next-line
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        })}
                      />
                    </fieldset>
                    {errors.email && (
                      <p style={{ color: "#C252E0" }}>Please provide a valid email</p>
                    )}

                    <fieldset>
                      <label>Phone number</label>
                      <input
                        type="text"
                        placeholder="Enter your phone number"
                        required
                        name="phone"
                        {...register("phone", {
                          required: true,
                          minLength: 8,
                          // eslint-disable-next-line
                          pattern: /^[0-9]*$/,
                        })}
                      />
                    </fieldset>
                    {errors.phone && (
                      <p style={{ color: "#C252E0" }}>Please provide a valid phone number</p>
                    )}
                    <fieldset>
                      <label htmlFor="message">Message</label>
                      <textarea
                        placeholder="Tell us what do you want to discuss with us"
                        rows="5"
                        tabIndex="4"
                        name="message"
                        className="message"
                        id="message"
                        required
                        {...register("message", {
                          required: true,
                          minLength: 20,
                        })}
                      />
                    </fieldset>
                    {errors.message && (
                      <p style={{ color: "#C252E0" }}>
                        Please provide a message of at least 20 characters.
                      </p>
                    )}

                    <label htmlFor="message">Pick a date</label>

                    <ScheduleMeeting
                      backgroundColor="#171f29"
                      borderRadius={10}
                      primaryColor="#7998EE"
                      eventDurationInMinutes={30}
                      availableTimeslots={availableTimeslots}
                      selectedStartTime={selectedDate}
                      onStartTimeSelect={(e) => {
                        setSelectedDate(e.startTime);
                      }}
                    />
                  </div>
                </div>

                <div className="wrap-btn">
                  <button type="submit" className="tf-button style2">
                    Submit request
                  </button>
                  <Toaster />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SubmitIGO;
