import React from "react";
import PropTypes from "prop-types";

import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

Featured.propTypes = {
  data: PropTypes.array,
};

function Featured(props) {
  const { data } = props;

  return (
    <section className="tf-section fueture">
      <div className="container w_1320">
        <div className="row">
          <div className="col-md-12">
            <div className="container_inner">
              <Swiper
                className="mySwiper"
                slidesPerView={3}
                spaceBetween={30}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
              >
                {data.slice(0, 3).map((item) => (
                  <SwiperSlide className="pb-30" key={item.id}>
                    <div className="icon-box">
                      <div className="top">
                        <div className="icon">
                          <img src={item.img} alt="" />
                        </div>
                        <div className="content">
                          <h5>
                            <Link to={item.link}>{item.title}</Link>
                          </h5>
                        </div>
                      </div>
                      <div className="bottom">
                        <img
                          src={require("../../assets/images/backgroup/bg_bt_box_1.png")}
                          alt=""
                        />
                      </div>
                      <div className="number">{item.id}</div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="col-md-12 mt30">
                <p className="desc text-center mb20">
                  at silverhand, we are committed to providing you with the best possible service.
                  <br className="show-destop" />
                  guide you through the process of becoming a silverhand member. we are here to help
                </p>
                <div className="wrap-btn justify-content-center">
                  <Link to="https://academy.silverhandsecurity.com/" className="tf-button style2">
                    JOIN US
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Featured;
