import HomeTwo from "./HomeTwo";

import TeamDetails from "./TeamDetails";
import SubmitIGO from "./SubmitIGO";
import Contact from "./Contact";
import AboutUs from "./AboutUs";
import Services from "./Services";

const routes = [
  { path: "/", component: <HomeTwo /> },
  { path: "/team_details", component: <TeamDetails /> },
  { path: "/about_us", component: <AboutUs /> },
  { path: "/services", component: <Services /> },
  { path: "/reservation", component: <SubmitIGO /> },
  { path: "/contact", component: <Contact /> },
];

export default routes;
