import img1 from "../images/common/team_1.png";
import img5 from "../images/common/team_5.png";
import img6 from "../images/common/team_7.png";
import img7 from "../images/common/team_4.png";
//!

const dataTeam = [
  {
    id: 1,
    img: img6,
    name: "Achraf Jday",
    social: "https://www.linkedin.com/in/achrafjday/",
    unit: "CEO",
  },

  {
    id: 2,
    img: img1,
    name: "Mahdi Soussi",
    social: "https://www.linkedin.com/in/mahdi-soussi/",
    unit: "CIO",
  },
  {
    id: 3,
    img: img5,
    name: "Wajih Tlili",
    social: "https://www.linkedin.com/in/wajih-tlili-26a204205/",
    unit: "CTO",
  },
  {
    id: 4,
    img: img7,
    name: "Med Jilani chagra",
    social: "https://www.linkedin.com/in/mohamed-jilani-chagra-7699a5210/",
    unit: "CPO",
  },
];

export default dataTeam;
