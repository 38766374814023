import React from "react";

import { Link } from "react-router-dom";

function CTA(props) {
  return (
    <section className="tf-section tf_CTA">
      <div className="container relative">
        <div className="row">
          <div className="col-md-6">
            <div className="tf-title left mt66" data-aos="fade-up" data-aos-duration="800">
              <h2 className="title">You Want To Talk To One Of Our Experts? </h2>
              <p className="sub">We are here to help you.</p>
              <div className="wrap-btn">
                <Link to="/reservation" className="tf-button style3">
                  RESERVE NOW!
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="image_cta" data-aos="fade-left" data-aos-duration="1200">
              {/*  <img
                className="move4"
                src={require("../../../assets/images/common/icon_4.png")}
                alt=""
              /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CTA;
