import img1 from "../images/common/Consulting Services.webp";
import img2 from "../images/common/Learning _ development.webp";
import img3 from "../images/common/service - Appointment.webp";
import img4 from "../images/common/icon_4.png";
import img5 from "../images/common/icon_5.png";
import img6 from "../images/common/icon_6.png";
import img7 from "../images/common/icon_7.png";
import img8 from "../images/common/icon_8.png";
//!
const dataFeatured = [
  {
    id: 1,
    img: img1,
    title: "Consulting Services",
    link: "/reservation",
  },
  {
    id: 2,
    img: img2,
    title: "Learning & Development",
    link: "https://academy.silverhandsecurity.com/",
  },
  {
    id: 3,
    img: img3,
    title: "Appointments & Scheduling",
    link: "/reservation",
  },

  {
    id: 4,
    img: img4,
    title: "Thriving Hacker Network",
    text: "Become an integral member of a global network tailored for businesses, where cybersecurity experts unite to exchange insights, tactics, and the excitement of mastering digital defense. Join our exclusive community to elevate your organization's capabilities and navigate the ever-evolving cybersecurity landscape with confidence and precision.",
  },
  {
    id: 5,
    img: img8,
    title: "Your Business's Gateway",
    text: "We serve as your gateway to the international stage, connecting your enterprise with industry-leading tech pioneers, unveiling distinctive prospects, and positioning your business for its ideal cybersecurity ventures. This is the platform where your business's future propels to new heights. Are you ready to embark on this journey?",
  },

  {
    id: 6,
    img: img6,
    title: "Cutting-Edge Content",
    text: "Curated meticulously by expert cybersecurity professionals, our content isn't just informative – it's transformative. Immerse your team in an interactive, hands-on learning journey that's as engaging as it is practical. You'll acquire the skills and knowledge to navigate the cybersecurity landscape with confidence and precision. ",
  },
  {
    id: 7,
    img: img5,
    title: "Ignite Innovation and Fortitude",
    text: "We do more than impart skills; we nurture the hacker mindset within your team. Through a fusion of ingenious strategies and unwavering determination, you'll unravel challenges that once appeared insurmountable. This driving force propels your organization to conquer the seemingly impossible, setting a precedent for triumph.",
  },
  {
    id: 8,
    img: img7,
    title: "Immersive Learning Experience",
    text: "Our gamified platform introduces a sophisticated experience system complemented by progressive levels, immersing you in captivating real-world simulations. Here, you'll cultivate the expertise to navigate and triumph over dynamic cybersecurity challenges. This holistic journey marries education with exhilaration.",
  },
];

export default dataFeatured;
