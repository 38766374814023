const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
  },
  {
    id: 2,
    name: "About Us",
    links: "/about_us",
  },
  {
    id: 3,
    name: "Services",
    links: "/services",
  },

  {
    id: 4,
    name: "Contact Us",
    links: "contact",
  },
];

export default menus;
