import React from "react";
import Banner from "../components/banner/banner_v2";
import dataFeatured from "../assets/fake-data/data-feature";
import dataProject from "../assets/fake-data/data-comingsoon";
import dataPartner from "../assets/fake-data/data-partner";
import Faqs from "../components/faqs";
import dataFaq from "../assets/fake-data/data-faqs";
import CTA from "../components/cta";
import Partner from "../components/partner";
import Featured2 from "../components/featured/Featured2";
import Project from "../components/project/project_v1";
import "../scss/component/styles.scss";
function HomeTwo() {
  return (
    <div className="header-fixed main home2 counter-scroll">
      <Banner />
      <div className="bg_body">
        <Partner data={dataPartner} />
        <Project data={dataProject} />
        <Featured2 data={dataFeatured} />
        <CTA />
        <Faqs data={dataFaq} />
      </div>
    </div>
  );
}
export default HomeTwo;
