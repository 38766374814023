import img1 from "../images/partner/01.png";
import img2 from "../images/partner/02.png";
import img3 from "../images/partner/03.png";
//!
const dataPartner = [
  {
    id: 1,
    img: img1,
    link: "https://middlex.io/",
  },
  {
    id: 2,
    img: img2,
    link: "#",
  },
  {
    id: 3,
    img: img3,
    link: "https://www.offsec.com/",
  },
];

export default dataPartner;
