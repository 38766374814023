import React, { useState } from "react";
import "../scss/component/_section.scss";
import "../scss/component/_box.scss";
import "../scss/component/_tf-section.scss";
import "../scss/component/_reponsive.scss";

import Team from "../components/team";
import dataTeam from "../assets/fake-data/data-team";

function AboutUs(props) {
  const [dataTitle] = useState({
    title: "Our Mission and Vision",
    text: "Our mission revolves around assuming a prominent role in the B2B cybersecurity e-learning sector, dedicated to equipping businesses with crucial knowledge and pragmatic skills for negotiating the digital domain with confidence and security. We envision a worldwide business landscape wherein cybersecurity stands as an inherent and steadfast component, guaranteeing enduring protection and adaptability in the face of evolving digital challenges. Through our comprehensive platform, we commit ourselves to catalyzing the realization of this vision – a future where businesses of all sizes operate within a fortified digital paradigm, safeguarding their interests and ensuring sustained growth.",
  });
  return (
    <div className="header-fixed main home1 counter-scroll page-title ">
      <section className="tf-section technology page-title">
        <div className="container w_1490">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="tf-title" data-aos="fade-right" data-aos-duration="800">
                <div
                  className="img_technology"
                  style={{
                    marginTop: "40%",
                    transform: "scale(3.0)",
                    marginRight: "30%",
                  }}
                >
                  <img
                    className="move4"
                    src={require("../assets/images/common/img_technology1.webp")}
                    alt="Silverhand"
                  />
                  {/*  <img
                    className="coin coin_1"
                    src={require("../assets/images/common/coin1.png")}
                    alt=""
                  />
                  <img
                    className="coin coin_2"
                    style={{ transform: "scale(2.5)" }}
                    src={require("../assets/images/common/coin2.png")}
                    alt=""
                  />
                  <img
                    className="coin coin_3"
                    src={require("../assets/images/common/coin3.png")}
                    alt=""
                  />
                  <img
                    className="coin coin_4"
                    src={require("../assets/images/common/coin4.png")}
                    alt=""
                  />
                  <img
                    className="coin coin_5"
                    src={require("../assets/images/common/coin5.png")}
                    alt=""
                  />
                  <img
                    className="coin coin_6"
                    src={require("../assets/images/common/coin6.png")}
                    alt=""
                  /> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="content_technology" data-aos="fade-up" data-aos-duration="800">
                <div className="tf-title">
                  <h2 className="title ">{dataTitle.title}</h2>
                </div>
                <p className="sub_technology">{dataTitle.text}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br></br>
      <br></br>
      <br></br>

      <Team data={dataTeam} />
    </div>
  );
}

export default AboutUs;
