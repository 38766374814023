import React from "react";
import dataProject from "../assets/fake-data/data-project";
import Step from "../components/Step/Step";
import dataStep from "../assets/fake-data/data-step";
import Project from "../components/project/project_v3";
import Countdown from "../components/countdown";
import Featured from "../components/featured/index";
import dataFeatured from "../assets/fake-data/data-feature";
function Services() {
  return (
    <div className="header-fixed   counter-scroll page-title ">
      <div className="col-md-12">
        <div className="tf-title" data-aos="fade-up" data-aos-duration="800">
          <h2 className="title">Securing the cyber world, one byte at a time... Loading</h2>
          <div className="featured-countdown style2">
            <p className="featured_title">Opening in</p>
            <span className="slogan"></span>
            <span className="js-countdown" data-timer="1865550">
              {<Countdown />}
            </span>
            <ul className="desc">
              <li>day</li>
              <li>hour</li>
              <li>min</li>
              <li>sec</li>
            </ul>
          </div>
        </div>
      </div>
      <Featured data={dataFeatured} />
      <Project data={dataProject} />
      <Step data={dataStep} />
    </div>
  );
}
export default Services;
