import React, { useEffect, useRef } from "react";
import "../styles.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import GlitchedWriter from "glitched-writer";
import Typewriter from "typewriter-effect";

function Banner() {
  const glitchRef = useRef(null);

  useEffect(() => {
    const writer = new GlitchedWriter(glitchRef.current, {
      steps: 10,
    });

    writer.write("Your portal into the cybersecurity realm.");
  }, []);

  return (
    <div className="page-title">
      <div className="slider-main">
        <Swiper>
          <SwiperSlide>
            <div className="slider-st2">
              <div className="overlay">
                <div style={{ width: "100%", height: "100%" }}>
                  <video
                    autoPlay
                    muted
                    loop
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                    poster={require("./frame0.png")}
                  >
                    <source src={require("./video.mp4")} type="video/mp4" />
                    <source src={require("./video.webm")} type="video/webm" />
                    <source src={require("./video.ogg")} type="video/ogg" />
                    Your browser doesn't support HTML5 video.
                  </video>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="box-slider">
                    <div className="content-box">
                      <h1 className="title" style={{ height: 8 + "vh", marginBottom: 2 + "vh" }}>
                        <span style={{ fontSize: "40px" }} ref={glitchRef}></span>
                      </h1>

                      <p className="sub-title mt-20 " style={{ marginTop: 15 + "vh" }}>
                        We provide exclusive cybersecurity services
                        <Typewriter
                          options={{
                            strings: [
                              "Guidance",
                              "Learning",
                              "Practice",
                              "Consulting",
                              "Support",
                              "Everything you need to become a cybersecurity expert!",
                              "Join us now!",
                            ],
                            autoStart: true,
                            loop: true,
                            deleteSpeed: 20,
                            cursor: "_",
                          }}
                        />
                      </p>

                      <div
                        className="wrap-btn"
                        style={{
                          paddingBottom: 10 + "vh",
                          marginTop: 8 + "vh",
                          color: "#e0d9f6",
                        }}
                      >
                        <a
                          className="tf-button style3"
                          href="https://academy.silverhandsecurity.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default Banner;
