import img3 from "../images/common/Sign up.webp";
import img2 from "../images/common/Select a course.webp";
import img1 from "../images/common/Start learning.webp";

//!
const dataProject = [
  {
    id: 1,
    img: img1,
    title: "Request a Customized Consultation",
    desc: "Initiate the onboarding process by encouraging businesses to request a personalized consultation with our cybersecurity experts. This step involves providing basic contact information and outlining specific challenges or objectives. This consultation sets the foundation for tailoring the learning journey to address the unique cybersecurity needs of the business.",
  },
  {
    id: 2,
    img: img2,
    title: "Receive a Customized Learning Plan",
    desc: "Following the consultation, businesses will receive a bespoke learning plan crafted by our experts. This plan outlines recommended courses, workshops, and resources that align precisely with the identified areas for improvement. This step emphasizes the value of individualized attention and expertise that SILVERHAND offers.",
  },
  {
    id: 3,
    img: img3,
    title: "Engage Your Team in Training",
    desc: "Once the learning plan is approved, businesses can initiate training for their team members. This step involves inviting employees to the SILVERHAND academy platform, where they can access the recommended courses and workshops. Progress tracking and reporting functionalities ensure that businesses can monitor the development of their team's cybersecurity skills.",
  },
];

export default dataProject;
