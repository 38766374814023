import img1 from "../images/common/Enroll in a course.webp";
import img2 from "../images/common/Complete course projects.webp";
import img3 from "../images/common/Earn Rewards.webp";

const dataStep = [
  {
    id: 1,
    img: img1,
    title: "Hands-On Virtual Machines",
    text: "SILVERHAND provides you with access to an array of virtual machines (VMs) for practical, real-world experience. These VMs offer a secure environment to practice and refine your cybersecurity skills, from penetration testing to incident response. This hands-on experience equips you with the confidence and competence to effectively manage cybersecurity challenges in your professional endeavors.",
  },
  {
    id: 2,
    img: img2,
    title: "Exclusive Badges and Recognition",
    text: "As you progress through our learning modules, you'll earn exclusive badges that showcase your proficiency in various cybersecurity skills. These badges not only recognize your accomplishments within the SILVERHAND community but also provide a visual representation of your expertise, setting you apart as a knowledgeable and security-conscious professional.",
  },
  {
    id: 3,
    img: img3,
    title: "Accredited Partner Certifications",
    text: "Joining SILVERHAND grants you the opportunity to earn accredited certifications from our esteemed partner companies. These certifications carry industry-wide recognition, affirming your mastery of crucial cybersecurity concepts and technologies endorsed by leading experts in the field.",
  },
];

export default dataStep;
