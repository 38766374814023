import React, { useState } from "react";
import PropTypes from "prop-types";

import { Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "../styles.scss";
import Countdown from "../../countdown";
import Typewriter from "typewriter-effect";

Project.propTypes = {
  data: PropTypes.array,
};

function Project(props) {
  const { data } = props;

  const [dataTitle] = useState({
    title_2: "coming soon",
  });

  return (
    <section className="tf-section project">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="tf-title" data-aos="fade-up" data-aos-duration="800">
              <h2 className="title">
                {dataTitle.title}
                <br className="show-destop" /> {dataTitle.title_2}
              </h2>
            </div>
          </div>
          <div className="col-md-12">
            <div className="project-wrapper">
              <div className="image-wrapper" data-aos="fade-in" data-aos-duration="1000">
                <div className="slider-1">
                  <Swiper
                    modules={[Pagination, A11y]}
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                  >
                    {data.map((item) => (
                      <SwiperSlide key={item.id} item={item}>
                        <img src={item.img} alt="preview" />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
              <div className="content-wrapper">
                <div className="content_inner" data-aos="fade-left" data-aos-duration="1200">
                  <div className="wrapper">
                    <ul className="price">
                      <li>
                        <Typewriter
                          options={{
                            strings: [
                              "SILVERHAND is under construction",
                              "SILVERHAND est en construction",
                              "SILVERHAND está en construcción",
                              "SILVERHAND está em construção",
                              "SILVERHAND è in costruzione",
                            ],
                            autoStart: true,
                            loop: true,
                            deleteSpeed: 20,
                            cursor: "_",
                          }}
                        />
                      </li>
                    </ul>
                    <p className="desc">
                      We are currently working on a new e-learning platform that will be available
                      soon. Stay tuned for more information.
                    </p>

                    <h6 className="featured_title">Loading... </h6>
                    <div className="featured-countdown">
                      <div className="js-countdown">{<Countdown />}</div>
                      <ul className="desc">
                        <li>day</li>
                        <li>hou</li>
                        <li>min</li>
                        <li>sec</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Project;
