import img1 from "../images/common/preview3.png";
import img2 from "../images/common/preview2.png";
import img3 from "../images/common/preview1.png";

//!
const dataProject = [
  {
    id: 1,
    img: img1,
    title: "Sign up",
    desc: "Create an account on our platform in just a few clicks. All you need is an email address and a password",
  },
  {
    id: 2,
    img: img2,
    title: "Select a course",
    desc: " Browse our course catalog and choose the one that best suits your needs. We offer a wide range of courses, from beginner to advanced, so there's something for everyone.",
  },
  {
    id: 3,
    img: img3,
    title: "Start learning",
    desc: "Once you've selected a course, you can start learning right away. Our platform offers interactive lessons, quizzes, and assignments to help you master the material. Plus, our expert instructors are always available to answer your questions and provide feedback.",
  },
];

export default dataProject;
