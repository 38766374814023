//!

const dataFaq = [
  {
    id: 1,
    title: "What is the E-Learning Cybersecurity Platform?",
    content:
      "Our E-Learning Cybersecurity Platform is a specialized online educational hub designed to empower businesses with the knowledge and skills needed to ensure their cyber environments are secure and resilient. We offer curated courses, training modules, and real-world scenario labs tailored for organizational needs.",
  },
  {
    id: 2,
    title: "How can this platform benefit my business?",
    content:
      "Investing in cybersecurity training can greatly reduce the risk of breaches, improve your team's response time to threats, and ensure compliance with industry regulations. Our platform will equip your staff with the latest cybersecurity practices, tools, and strategies to protect your organization's assets.",
  },
  {
    id: 3,
    title: "Do my employees need any prior cybersecurity experience?",
    content:
      "No. Our platform caters to varying levels of expertise. From foundational cybersecurity principles for newcomers to advanced threat response strategies for IT professionals, we have courses to meet your business needs.",
  },
  {
    id: 4,
    title:
      "Can we request custom content tailored to our industry or specific cybersecurity concerns",
    content:
      "Absolutely. We work closely with businesses to develop custom content that addresses specific industry threats, regulations, and compliance requirements.",
  },
  {
    id: 5,
    title: "What support do you offer to businesses?",
    content:
      "We provide 24/7 technical support, a dedicated account manager for content and course planning, and periodic cybersecurity updates in line with the latest industry trends and threats.",
  },
  {
    id: 6,
    title: " How do I get started?",
    content:
      "Reach out to our Sales team to schedule a demo, discuss your requirements, and get your business onboarded. We're committed to making the onboarding process smooth and efficient.",
  },
];

export default dataFaq;
